<template>
  <div class="page_medium_top_margin">
    <div class="row">
      <div class="col-md-12">
        <div class="__reporting_nav__container">
          <button
            @click="campaignDetails"
            class="__reporting__nav notranslate"
            :class="[
              $route.path.includes('history') ? '__reporting__nav_active' : '',
            ]">
            {{
              $t("dashboard.campaign_history.button_history.campaign_history")
            }}
          </button>
          <button
            @click="reporting"
            class="__reporting__nav"
            :class="[
              $route.path.includes('/sms/campaign/delivery_report')
                ? '__reporting__nav_active'
                : '',
            ]">
            delivery report
          </button>
        </div>
      </div>
    </div>
    <router-view> </router-view>
  </div>
</template>

<script>
  import loginType from "@/helpers/loginType";
  export default {
    name: "reporting",
    data() {
      return {};
    },
    methods: {
      campaignDetails() {
        loginType() == "sms"
          ? this.$router.push("/sms/campaign/history").catch((err) => {})
          : this.$router.push("/voice/campaign/history").catch((err) => {});
        // ! Find Purpose of this
        if (this.$route.path == "/sms/campaign/delivery_report") {
          if (loginType() == "sms") {
            return this.$router
              .replace("/sms/campaign/history")
              .catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
              });
          } else {
            return this.$router
              .replace("/voice/campaign/history")
              .catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
              });
          }
        }
      },
      reporting() {
        loginType() == "sms"
          ? this.$router.push("/sms/campaign/delivery_report").catch((err) => {
              if (err.name !== "NavigationDuplicated") throw err;
            })
          : this.$router
              .push("/voice/campaign/delivery_report")
              .catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
              });
      },
    },
    mounted() {
      // Analytics
      loginType() == "sms"
        ? this.$mixpanel.track("SMS Campaign History Page")
        : this.$mixpanel.track("Voice Campaign History Page");

        if (this.$store.getters["auth/currentUser"].country === "CI") {
          loginType() == "sms"
            ? this.$mixpanelFranco.track("SMS Campaign History Page")
            : this.$mixpanelFranco.track("Voice Campaign History Page");
        }
    },
  };
</script>

<style scoped>
  @media screen and (max-width: 820px) {
    body,
    html {
      overflow: auto;
    }

    .page_medium_top_margin {
      margin-top: 0px !important;
      overflow-y: scroll;
      height: 100%;
      padding: 0 !important;
      margin: 0 !important;
      width: auto !important;
      overflow-x: hidden !important;
    }

    .page_medium_top_margin::-webkit-scrollbar {
      width: 0px;
    }
  }
</style>
