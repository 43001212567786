var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_medium_top_margin"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"__reporting_nav__container"},[_c('button',{staticClass:"__reporting__nav notranslate",class:[
            _vm.$route.path.includes('history') ? '__reporting__nav_active' : '',
          ],on:{"click":_vm.campaignDetails}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.campaign_history.button_history.campaign_history"))+" ")]),_c('button',{staticClass:"__reporting__nav",class:[
            _vm.$route.path.includes('/sms/campaign/delivery_report')
              ? '__reporting__nav_active'
              : '',
          ],on:{"click":_vm.reporting}},[_vm._v(" delivery report ")])])])]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }